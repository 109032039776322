<template>
  <div class="pb-1 pt-1">
    <div class="row justify-content-md-center">
      <div class="col-6">
        <div class="input-group">
          <input class="form-control"
             id="srch-term"
             name="srch-term"
             type="text"
             placeholder="Search"
             autocomplete="off"
             v-model="filterText"
             @keyup.enter="doFilter" />
          <div class="input-group-append">
            <button class="btn btn-primary search-btn"
                    type="submit"
                    @click="doFilter">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <button class="btn btn-outline-primary ml-2" @click="resetFilter">
            <i class="fas fa-sync-alt"></i>
          </button>

          <button class="btn btn-outline-success btn-sm add-btn ml-2"
                  type="button"
                  @click="addInfluencer">
            <i class="fas fa-plus"></i> Add Influencer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const AddInfluencer = () => import('./AddInfluencer.vue');
  export default {
    data() {
      return {
        filterText : '',
      }
    },
    methods : {

      /**
       * Filter Action
       */
      doFilter() {
        this.$events.fire('filter-set', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        this.$events.fire('filter-reset');
      },

      /**
       * Add User
       */
      addInfluencer() {
        this.$modal.show(
          AddInfluencer,
          {},
          {
            height       : 'auto',
            adaptive     : true,
            clickToClose : false,
          },
          {
            // eslint-disable-next-line no-unused-vars
            'add-influencer-successful' : (event) => {
              this.$events.fire('add-influencer');
            },
          },
        );
      },

    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/filter-bar";
</style>